import React from 'react';
import { styled } from '@mui/material';

const StyledDiv = styled('div')(() => ({
  root: {
    paddingTop: '20px !important',
    paddingBottom: '50px !important',
    textAlign: 'left',
  },
}));

export interface IAgreementProps {
  className?: string;
}

class Agreement extends React.Component<IAgreementProps> {
  render() {
    return (
      <StyledDiv>
        <p>
          <em>Definition of Terms</em>
        </p>
        <p>
          <strong>Data:</strong> Raw, basic elements like numbers, dates, names, without inherent meaning.
        </p>
        <p>
          <strong>Information:</strong> Data that has been analyzed, structured, and given context to provide valuable insights.
        </p>
        <p>
          <strong>Personal Information:</strong> Information identifying yourself that you provide to us in the registration process.  This Personal Information will be protected and not shared with any other user.  You may request that this Personal Information be deleted from our records if you no longer wish to be a registered user.
        </p>
        <p>
          <em>Privacy and Security</em>
        </p>
        <p>
          The Buildings Data Platform is provided as a resource to benefit users
          and visitors seeking data related to buildings. The Buildings Data
          Platform supports the U.S. Department of Energy’s Office of Energy
          Efficiency and Renewable Energy’s Building Technologies Office's goal
          of continually developing innovative, cost-effective energy saving
          solutions—better products, better new homes, better ways to improve
          older homes, and better buildings in which we work, shop, and lead our
          everyday lives.
        </p>
        <p>
          Unless otherwise noted, the following policies apply to all pages and
          sites in the bbd.labworks.org domain.
        </p>
        <p>
          <em>Information Collected and Stored Automatically</em>
        </p>
        <p>
          We store all information provided via the website. This may include
          some personal data about our site visitors (hereafter “you”) to the
          extent effectively provided to us by the website. Example information
          includes: your name, Internet Protocol (IP) address, user
          identification (ID), and e-mail address. Your data may be stored in
          our databases until you request removal of said information.
        </p>
        <p>
          Information collected by Buildings Data Platform is NOT shared with
          anyone beyond the support staff for this website and other appropriate
          staff—except when required by computer security, law enforcement
          investigation or other authorized legal purpose. The information also
          may be used as a source of anonymous statistical information. You
          cannot be identified on the basis of this statistical information.
        </p>
        <p>
          <em>Cookies</em>
        </p>
        <p>
          Any cookies in the bbd.labworks.org domain are non-persistent
          (per-session) cookies only available during an active browser session.
          Hence, they do not permanently record data and are not stored on your
          computer’s hard drive. These cookies are erased as soon as you leave
          the Buildings Data Platform website.
        </p>
        <p>
          <em>Personal Information</em>
        </p>
        <p>
          Due to the nature of mission, you may elect to provide personal
          information via the website for registration or request for
          information. Said information is collected merely to meet the needs of
          its stated purposes, e.g., establishing a user account to acquire or
          share project data.
        </p>
        <p>
          Buildings Data Platform does not collect information for commercial
          marketing purposes.
        </p>
        <p>
          You also have the right to access, correct, and/or remove all personal
          data stored by Buildings Data Platform. For more information, please
          contact bbdteam@pnnl.gov.
        </p>
        <p>
          <em>Spam</em>
        </p>
        <p>
          To curtail incidents of spam, Buildings Data Platform employs
          reCAPTCHA®, which protects websites from bots. Any personal data you
          share via the Buildings Data Platform also may be assessed to
          determine if your message constitutes spam. Buildings Data Platform
          will never provide your email address to third parties and will never
          send any spam to you.
        </p>
        <p>
          <em>External Links</em>
        </p>
        <p>
          The Buildings Data Platform may include links to websites created and
          maintained by other public and/or private organizations. These links
          are solely for your information and convenience. By clicking away from
          Buildings Data Platform to an outside website, you are subject to the
          privacy and security policies indicated by the owner(s) and sponsor(s)
          of those external sites.
        </p>
        <p>
          Buildings Data Platform is not responsible for website content created
          and maintained by other public and/or private organizations.
        </p>
        <p>
          <em>Site Revisions</em>
        </p>
        <p>
          On occasion, Buildings Data Platform may have a need to amend its
          services, which could require an update to our privacy and security
          policies. Continued use of the Buildings Data Platform website
          following the posting of any changes to our privacy and security
          policy means you agree to and accept these changes.
        </p>
        <p>
          <em>Disclaimer</em>
        </p>
        <p>
          This material was prepared as an account of work sponsored by an
          agency of the United States Government. Neither the United States
          Government nor the United States Department of Energy, nor the
          Contractor, nor any or their employees, nor any jurisdiction or
          organization that has cooperated in the development of these
          materials, makes any warranty, express or implied, or assumes any
          legal liability or responsibility for the accuracy, completeness, or
          usefulness or any information, apparatus, product, software, or
          process disclosed, or represents that its use would not infringe
          privately owned rights.
        </p>
        <p>
          Reference herein to any specific commercial product, process, or
          service by trade name, trademark, manufacturer, or otherwise does not
          necessarily constitute or imply its endorsement, recommendation, or
          favoring by the United States Government or any agency thereof, or the
          Contractor. The views and opinions of authors expressed herein do not
          necessarily state or reflect those of the United States Government or
          any agency thereof.
        </p>
      </StyledDiv>
    );
  }
}

export default Agreement;