import {
  default as BaseDataset,
  DatasetStats as BaseDatasetStats,
  DatasetRawStats as BaseDatasetRawStats,
} from '@dapclient/models/Dataset';
import RealTimeData from '@extensions/models/RealTimeData';

export type DatasetStats = BaseDatasetStats;
export type DatasetRawStats = BaseDatasetRawStats;

export interface IBuildingLocation {
  anonymized?: boolean;
  city: string;
  state: string | string[];
  zipCode: number | string;
  country: string;
  climateZone: string | string[];
}

export interface IBuildingCharacteristics {
  name: string;
  type: string;
  yearBuilt: number;
  numberFloors: number;
  totalFloorArea: number;
  conditionedFloorArea: number;
  numberPeople: 66;
  wallRvalue?: string;
  ceilingRvalue?: string;
  foundationRvalue?: string;
  windowUfactor?: string;
  additionalInfo?: string;
}

export interface ISystemCharacteristics {
  fuelType: string;
  hvacSystemType: string;
  lightingSystemType: string;
  pluginEquipmentType: string;
  waterHeatingType: string;
  other: string;
}

export enum BuildingDataCategory {
  ENERGY_USE = 'Energy use data',
  POWER_GENERATION = 'Onsite power generation data',
  INDOOR_ENVIRONMENTAL = 'Indoor environmental data',
  OUTDOOR_ENVIRONMENTAL = 'Outdoor environmental data',
  SYSTEMS_OPERATIONS = 'Systems and equipment operational data',
  CONTROL_AND_LOGIC = 'Control setting and logic data',
  OCCUPANT = 'Occupant data',
  BASIS_OF_DESIGN = 'Basis of design data',
  ASSET = 'Building and system asset data',
  UTILITY_AND_GRID = 'Utility rates and grid signal data',
}

export default class Dataset extends BaseDataset {
  buildingLocation: IBuildingLocation;
  buildingCharacteristics: IBuildingCharacteristics;
  systemCharacteristics: ISystemCharacteristics;
  dataCategory: BuildingDataCategory[];
  dataSubCategory: BuildingDataCategory[];
  license: string;
  dataFormats: string[];
  dataFilesDescription: string | null;
  rawDataDoi?: string | null;
  realtimeData?: RealTimeData | null | undefined;
  constructor(response) {
    super(response);
    this.rawDataDoi = response.meta.rawDataDoiName;
    if (response.meta.realtimeData) {
      this.realtimeData = response.meta.realtimeData;
    }
    this.buildingLocation = response.meta.geoInformation;
    this.buildingCharacteristics = response.meta.buildingCharacteristics;
    this.systemCharacteristics = response.meta.systemCharacteristics;
    this.dataCategory = response.meta.dataCategory;
    this.dataSubCategory = response.meta.dataCategorization;
    this.license = response.meta.license;
    this.dataFormats = response.meta.dataFormat;
    this.dataFilesDescription =
      'dataFilesDescription' in response.meta
        ? response.meta.dataFilesDescription
        : null;
  }
}
